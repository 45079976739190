#Slider{
   width: 100%;
  height: 100vh;
overflow: hidden;
position: relative;
}

.bgImg{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: blur(1px);
}
.sliderDesc{
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  line-height: 5rem;
  text-align: center;
  letter-spacing: 3px;
}

#Slider::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:black;
 opacity: 0.7;
  z-index: 10;
}

.sliderTitle{
  font-size: 2.5rem;
  font-weight: 600;
  white-space:nowrap;
}
.sliderTxt{
  font-size: 1.5rem;
  text-transform: capitalize;
  white-space:nowrap;
  color:rgb(170, 170, 170);
}


.sliderDesc .sliderTitle,.sliderBtn{
  z-index: var(--z-fixed);
  text-transform: uppercase;

}

.sliderBtn{
  background:var(--first-color); 
   font-size: 1rem;
  padding: 0.8rem 2rem;
  border: var(--first-color) 2px solid;
   color:white;
 transition: 0.3s;
 margin-right: 10px; 
}


.sliderBtn:hover{
  background:black !important;
     border: black 2px solid !important;
  /* linear-gradient(90.21deg,#7e7ce9 -5.91%,#1f3e79 111.58%) */

}
.arrowIcon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  width: 80px;
    height: 80px;
    cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
z-index:var(--z-tooltip);
color: white;
}

.arrowIcon:hover{
  background-color: black;
opacity: 0.5;

  }

.dotContainer{
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
z-index: var(--z-tooltip);
display: flex;
justify-content: space-around;
 
}

.dotIcon{
  font-size: 60px;
 
}

@media screen and (max-width:768px) {
  .arrowIcon{
    display: none;
  }
  .sliderBtn {
 
    font-size: 11px;
    padding: 10px;
  margin: 0;
}

.sliderTitle{
  font-size: 1.4rem;
  margin-bottom: var(--mg-1);
}
.sliderTxt{
  font-size: 1rem;
/* display: none; */
}
.sliderDesc{
  
  line-height: 3rem;
 
}
}