#contact{
  background-image: url("../../assets/contact1.jpg");
   margin: auto;
  width: 100%;
    position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 font-family: var(--header-font); 

}

.contactContainer{
  padding: var(--normal-padding);
  /* background-color: yellow; */
}
#contact::before{
  position: absolute;
  content: "";
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color:black;
  opacity: 0.5;
}


.contactContent{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: var(--z-tooltip);
  text-align: center;
 
 
}


.contactTitle{
  color:white
}

.contactTitle{
  text-transform: capitalize;
}


.contactContent input,textarea,button {
outline: none;
padding: 10px;
height: 48px;
}


.userInfo{
   width: 100%;
   display: flex;
   justify-content: space-between;
  margin-bottom: var(--mg-6);
 }

 .userInfoRow{
  display: flex;
  align-items: center;
  gap: var(--mg-3);
}

.msg{
  width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
 margin-bottom: var(--mg-6);
 /* background:pink */
}

.msg textarea{
  flex-grow: 1;
}


.contactContent button{
  margin: auto;
  border:none;
  padding: 10px 25px;
  width: 25%;
  border-radius: 3px;
  font-size: 15px;
color:black;
text-transform: capitalize;
   min-width: 180px;
  transition: 0.5s;
  background-color:white;
 
}

.contactContent button:hover{
  /* background:var(--first-color); */
  background:black;

 color: white;
}

.contactContent button:disabled{
  background-color: var(--text-color);
  cursor: not-allowed;
}

.contactContent span{
  margin-right: var(--mg-3);
}
.sendIcon{
  margin-right: 10px;
}
.sent{
  border-radius: 5px;
margin: auto;
  color:green;
  width: 70%;
  padding: 10px 25px;
  font-weight: bolder;
}

.fail{
  border-radius: 5px;
  margin: auto;
    color:red;
    width: 70%;
    padding: 10px 25px;
}
@media screen and (max-width:990px) {
  .contactContainer{
    padding:var(--mobile-padding);
   
  }
  .contactContent{
  grid-template-columns: 1fr;
  /* padding: var(--mg-3); */
}
.contactContent button{
    padding: 10px;
width: 100%;
}
.userInfo  {
  flex-direction: column;
  text-align: center;
  gap: var(--mg-2)
}

.userInfoRow,.msg{
display: grid;
grid-template-columns: 1fr;
gap:var(--mg-1);
text-align: start;
}


}