.title {
  position: relative;
  font-size: 32px;
text-transform: uppercase;
  text-align: center;
 
}
.title:before {
  content: "";
  position: absolute;
  width: 80px;
  height: 2px;
  top: 50%;
  left: 32%;
}
.title:after {
  content: "";
  position: absolute;
  width: 80px;
  height: 2px;
  top: 50%;
  right: 32%;
}


.title {
  color: var(--first-color);

}

.title:after ,.title:before{

  background-color:  var(--text-color);
}