#footer{ 
  background:#E1E1E1;
  color:var(--text-color);
  text-align: center;
 }

.footerContainer{
  padding: var(--mg-5) var(--mg-4);
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  justify-content: space-between;  
}

.footerUl{
  display: flex;
  flex-direction: column;
  gap:var(--mg-2);
  margin: var(--mg-1) 0 var(--mg-2) 0;
 justify-content: space-between;
 /* background:pink */
}

.contactLi{
  display: flex;
}



.col{
  /* background:pink; */
  text-align: start;
}

.footerTitle{
   margin-bottom: var(--mg-2);
   color:black;
   /* color:var(--first-color); */
  font-weight: bold;
  font-family: Inter, sans-serif;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.contactIcon{
 font-size: 1.5rem;
 margin-right: 10px;
 width: 20px;
    height: 20px;
}

.footerP{
  text-transform: capitalize;
  letter-spacing: 1.5px;
  color:var(--text-color);
}

.copyright{
  /* font-size: 16px; */
background-color: #011B2A;
  letter-spacing: .32px;
  line-height: 70px;
  color: white;
  margin: 0px;
  padding: 0px;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.contactDesc{
  letter-spacing: 1.5px;
}


@media screen and (max-width:990px) {

  .footerContainer {
   flex-direction: column;
   gap: var(--mg-3);
}
.col {
  text-align: center;
}
.contactLi{
  justify-content: center;
}

}