#services{
  background: var(--third-color);
  width: 100%;
  padding: var(--normal-padding);
  /* padding: var(--padding-bottom) 0; */
}

 

.serviceContainer{
  margin: auto; 
}

.serviceSubtitle{
  letter-spacing: 1px;
  line-height: 2.2rem;
  font-size: 20px;
  text-align: justify;
  color: var(--text-color);
  width: 90%;
  text-align: center;
 /* background-color: pink; */
  margin:var(--mg-6) auto;
}

.serviceUL{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:100px;
  margin: auto;
  width: 80%;
}


.serviceUL .serviceTitle,.serviceIcon,.serviceTxt{
color: var(--text-color);
position: relative;
  z-index: var(--z-tooltip);

}



.serviceLi{
  text-align: center;
  display: grid;
 background-color: white;
 grid-template-rows: 0.2fr 0.2fr 2fr;
padding: 35px 40px;
border-radius: 5px;
transition: 0.5s;
gap:var(--mg-3);
position: relative;
overflow: hidden;
 /* background-color: pink; */
}


.serviceLi:hover{
  box-shadow:   0px 0px 20px 5px rgba(88, 88, 88, 0.48);

}

.serviceIcon{
  font-size: 70px;
  color: var(--first-color);
}

.serviceTitle{
  text-transform: uppercase;
}


.serviceTxt{
  letter-spacing: 1px;
    line-height: 2.2rem;
    font-size: 1.2rem;
    color: rgb(161, 161, 161);
    
}

.bgTxt{
transform: translateX(-101%);
  letter-spacing: 1px;
  line-height: 2.2rem;
  font-size: 1.2rem;
  background: var(--bg-linear);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding:20px ;
  transition: 1s;
  /* text-align: center; */
  z-index: var(--z-tooltip);
 width: 100%;
 height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.serviceLi:hover .bgTxt{
transform: translateX(0);

}

@media screen and (max-width:990px) {
  .serviceUL{
     width: 100%;
  }
  .serviceSubtitle {
    width: 100%;
}
}

@media screen and (max-width:768px) {
  #services{
    padding: var(--mobile-padding);
  }
  .serviceUL{
    grid-template-columns: 1fr;
     gap:50px;
     width: 100%;
  }

  .serviceTxt {
    font-size: 1.1rem;
    line-height: 30px;
    letter-spacing: .32px;
    color: #898989;
}

.bgTxt{
  font-size: 16px;
    line-height: 30px;
    letter-spacing: .32px;
    padding: var(--mg-1);
}
.serviceSubtitle{
  font-size: 17px;
  line-height: 30px;
    letter-spacing: .34px;
    width: 100%;
}

.serviceLi{
padding: 25px;
gap:var(--mg-2)
}
}
