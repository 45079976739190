*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif
}


/* CSS root variables */
:root{
  --header-height:5rem;
  --font-semi:600;
  --max-width:1200px;
  --padding-bottom:130px;
  --padding-LR:var(--mg-4);
  --mobile-padding:5.5rem var(--mg-4);
  --normal-padding:var(--padding-bottom) var(--mg-4);
}

.containerWidth {
    margin: auto;
  max-width: var(--max-width);

}
/* CSS colors */
:root{
  --first-color:#0052FF;
  --second-color:#009FE9;
  --third-color:#EBF0F7;
  --orange-color:#FF5601;
  --footer-bg-color:#020101;
  --red-color:#F54F4F;
  /* --text-color:#898989; */
  --text-color:#666666;
  --light-gray:#EEEEEE;
  --bg-linear:linear-gradient(90.21deg,#7e7ce9 -5.91%,#1f3e79 111.58%)
}


/* font styles */
:root{
  --header-font:"Poppins", sans-serif;
  --body-font:"Mark Script",cursive;
  --big-font-size:3.5rem;
  --h2-font-size:2rem;
  --normal-font-size:1rem;
}

/* margins */
:root{
  --mg-1:0.5rem;
  --mg-2:1rem;
  --mg-3:1.5rem;
  --mg-4:2rem;
  --mg-5:2.5rem;
  --mg-6:3rem;
}

h1{
  font-size: 3.5rem;
}


h3{
  font-size: 2rem;
}
/* z-index */
:root{
  --z-back:-10;
  --z-normal:1;
  --z-tooltip:10;
  --z-fixed:100
}

html{
  scroll-behavior: smooth;
}


h1,h2,p{
  margin:0
}

ul{
  margin:0;
  padding:0;
  list-style: none;
}

a{
  text-decoration: none;
}

img{
  max-width: 100%;
  height: auto;
  display: block;
}


button,.dotIcon{
  cursor: pointer;
}


*::before,::after{
  box-sizing: border-box;
}



.section_title{
  /* position:relative; */
  font-size: var(--h2-font-size);
color:var(--first-color);
margin-top:var(--mg-2);
margin-bottom:var(--mg-6);
text-transform: uppercase;
/* z-index: var(--z-tooltip); */
font-family: "Montserrat", sans-serif;
font-weight: bold;
letter-spacing: .90px;
}

.section_title:nth-child(1){
  margin-top: 0;
}
 
.section{
  padding-top:3rem;
  padding-bottom:2rem ;
  position:relative
}

 body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* media screen  */
@media screen and (max-width:768px){

  :root{
    --big-font-size:2rem;
    --h2-font-size:1.5rem;
    --normal-font-size:0.938rem;
  }
  h1{
    font-size: 35px;
  }
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 1.5rem;
  }
  h4{
    font-size: 1rem;
  }
  .section_title{
  margin-bottom:var(--mg-5);
  }
 

}