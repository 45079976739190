
.headerContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-fixed);
  height: var(--header-height);
  background:transparent ;
  transition: 1s;
  box-shadow: 6px 6px 16px 0 rgb(17 17 49 / 12%);

}

.active{
background:linear-gradient(90.21deg,var(--first-color) 100%,rgb(0,78,194) 111.58%); 
}

.header{
  display: flex;
height: 100%; 
   justify-content: space-between;
    align-items: center;
margin: auto;
max-width: var(--max-width);
width: 100%;
padding: 0 var(--mg-4);
}

.logoImg{
  width: 150px;
   object-fit: contain;
 }


 .toggleIcon{
   color: white;
   font-size: 25px;
   width: 25px;
   height: 25px;
   display: none;
 }


.navUL{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLink{
   border-radius: 5px;
  height: 30px;
transition: 0.5s;
letter-spacing: 1px;
text-transform:uppercase;
display: flex;
align-items: center;
padding: 20px;
font-weight: 600;
 color: white;
 cursor: pointer;
}



.navLink:hover{
  background-color: white;
  color:var(--first-color);
}

@media screen and (max-width:990px) {
  .toggleIcon{
       display: block;
  }
  .show{
    display: flex;
  }
  .hidden{
  display: none;
  }

  .header{
    padding: 0 var(--mg-4);
  }

  .logoImg{
    width: 110px;

   }

   .navUL{
   flex-direction: column;
     position: fixed;
   top: var(--header-height);
   background-color: var(--first-color);
   right: 0;
   height: calc(100vh - var(--header-height));
gap: var(--mg-4);
   justify-content:start;
   width:50%;
   padding: 2rem
  } 

}