#testimonials{
  padding: var(--normal-padding);
   color:var(--text-color);
}

.tMain{
  display: flex;
  /* gap:var(--mg-5); */
  align-items: center;
justify-content:space-between ;
/* margin-bottom:70px; */
}

.tImg{
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: auto;
  /* border:20px solid orange */
  /* border:20px solid var(--orange-color); */
  object-fit: cover;


}

.tContent{
  text-align: start;
  width: 50%;
}

.quoteIcon{
/* color: var(--orange-color); */
margin:0 var(--mg-1);
 
}
.tP{
  letter-spacing: 1px;
  line-height: 2 ;
  font-size: 1.2rem;
  color: rgb(161, 161, 161);
  text-align: justify;
}

.tName{
  margin: var(--mg-4) 0 var(--mg-2) 0;
font-size: 25px;
font-weight:bolder;
letter-spacing: 2px;
text-transform: capitalize;
/* color: rgb(161, 161, 161); */
 /* color: var(--text-color); */
 color: var(--text-color);
text-align: right;
 /* color: var(--first-color); */

}

.tCompany{
letter-spacing: 1px;
 text-transform: capitalize;
font-size: large;
 color: rgb(161, 161, 161);
text-align: right;

 /* color: var(--text-color); */
}


@media screen and (max-width:990px){
  #testimonials{
    padding:var(--mobile-padding); 
  }
  .tMain{
flex-direction: column;
 gap:var(--mg-5);
  }

  .tContent {
    text-align: center;
    width: 100%;
}
.tP{
  font-size: 1.1rem;
  line-height: 30px;
  letter-spacing: .66px;
}
.tName{

font-size: 20px;


}
}