#about{
 
  /* padding: var(--padding-bottom) 0; */
  padding: var(--normal-padding);
  display: grid;
  grid-template-columns: 1fr 1fr;
  color:var(--text-color);
  gap:100px;
}


.aboutContent{
  letter-spacing: 1px;
  line-height: 2.2rem;
  font-size: 20px;
  text-align: justify
}

.aboutImg{
  width: 100%;
  height: 100%;
}


@media screen and (max-width:990px){
  #about{
    grid-template-columns: 1fr;
    padding:var(--mobile-padding);
  gap:50px;

  }
  .aboutContent{
   
    font-size: 1.1rem;
    line-height: 30px;
      letter-spacing: .34px;
      margin: 0px;
      padding: 0px; 
 
}
}